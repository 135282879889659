var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_vm.client !== null ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.id,
      "disabled": "disabled",
      "label": "ID"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.states,
      "disabled": !_vm.isManualDrivenState(_vm.form.state) && !_vm.$helper.permission.canChangeNotManualState(_vm.user, _vm.form.state),
      "label": "Статус",
      "clearable": true
    },
    on: {
      "change": _vm.onChangeState
    },
    model: {
      value: _vm.form.state,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "state", $$v);
      },
      expression: "form.state"
    }
  }), _vm.form.errors.has('state') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('state')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__yellow",
    attrs: {
      "label": "Онлайн"
    },
    model: {
      value: _vm.form.isOnline,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isOnline", $$v);
      },
      expression: "form.isOnline"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__orange",
    attrs: {
      "label": "Вторичный"
    },
    model: {
      value: _vm.form.isDouble,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isDouble", $$v);
      },
      expression: "form.isDouble"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_vm.$helper.permission.canAssignClient(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Юрист",
      "items": _vm.lawyers,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.lawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyer", $$v);
      },
      expression: "form.lawyer"
    }
  }), _vm.form.errors.has('lawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyer')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.$helper.permission.canAssignClient(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Помощник юриста",
      "items": _vm.lawyers,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.lawyerAssistant,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerAssistant", $$v);
      },
      expression: "form.lawyerAssistant"
    }
  }), _vm.form.errors.has('lawyerAssistant') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerAssistant')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.$helper.permission.canChangeSubdivision(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Подразделение",
      "items": _vm.subdivisions,
      "item-value": "id",
      "item-text": "name",
      "clearable": true
    },
    model: {
      value: _vm.form.subdivision,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subdivision", $$v);
      },
      expression: "form.subdivision"
    }
  }), _vm.form.errors.has('subdivision') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('subdivision')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.$helper.permission.canChangeSubdivision(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Офис",
      "items": _vm.offices,
      "item-value": "id",
      "item-text": "name",
      "clearable": true
    },
    model: {
      value: _vm.form.office,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "office", $$v);
      },
      expression: "form.office"
    }
  }), _vm.form.errors.has('office') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('office')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Доп. юрист",
      "items": _vm.lawyers,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.additionalLawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalLawyer", $$v);
      },
      expression: "form.additionalLawyer"
    }
  }), _vm.form.errors.has('additionalLawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalLawyer')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "От кого"
    },
    model: {
      value: _vm.form.fromSource,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fromSource", $$v);
      },
      expression: "form.fromSource"
    }
  }), _vm.form.errors.has('fromSource') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('fromSource')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Тема обращения"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }), _vm.form.errors.has('subject') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('subject')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_vm.client !== null ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt,
      "disabled": "disabled",
      "label": "Дата создания"
    }
  })], 1) : _vm._e(), _vm.client !== null ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.assignedAt,
      "disabled": "disabled",
      "label": "Дата привязки"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.areas,
      "item-value": "id",
      "item-text": "name",
      "label": "Область права",
      "clearable": true
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _vm.form.errors.has('area') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('area')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Имя"
    },
    model: {
      value: _vm.form.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  }), _vm.form.errors.has('userName') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userName')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '##.##.####',
      expression: "'##.##.####'"
    }],
    attrs: {
      "label": "Год рождения"
    },
    model: {
      value: _vm.form.userBirthday,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userBirthday", $$v);
      },
      expression: "form.userBirthday"
    }
  }), _vm.form.errors.has('userBirthday') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userBirthday')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-## #',
      expression: "'+7 (###) ###-##-## #'"
    }],
    attrs: {
      "label": "Телефон"
    },
    model: {
      value: _vm.form.userPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userPhone", $$v);
      },
      expression: "form.userPhone"
    }
  }), _vm.form.errors.has('userPhone') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userPhone')) + " ")]) : _vm._e()], 1), _vm.form.userPhone ? _c('v-col', [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "tel:".concat(_vm.form.userPhone)
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")])], 1)], 1)]) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.form.userEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userEmail", $$v);
      },
      expression: "form.userEmail"
    }
  }), _vm.form.errors.has('userEmail') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userEmail')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Доверитель"
    },
    model: {
      value: _vm.form.principal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "principal", $$v);
      },
      expression: "form.principal"
    }
  }), _vm.form.errors.has('principal') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('principal')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Комментарий",
      "rows": "2"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Проблема"
    },
    model: {
      value: _vm.form.problem,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problem", $$v);
      },
      expression: "form.problem"
    }
  }), _vm.form.errors.has('problem') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('problem')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "3"
    }
  })], 1)], 1)])], 1) : _vm._e(), _c('div', [_vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_vm.$helper.permission.canUpdateContract(_vm.user) ? _c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.client && _vm.client.id ? _vm.update(false) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить ")]) : _vm._e(), _vm.$helper.permission.canUpdateContract(_vm.user) ? _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.client && _vm.client.id ? _vm.update(true) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить и закрыть ")]) : _vm._e()], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1), _vm.client && _vm.client.id ? _c('ClientVisits', {
    attrs: {
      "id": _vm.client.id
    }
  }) : _vm._e(), _vm.$helper.permission.canSeeRequest(_vm.user) ? _c('div', {
    staticClass: "my-10"
  }, [_vm.client ? _c('RequestGrid', {
    attrs: {
      "title": "\u0417\u0430\u044F\u0432\u043A\u0438 \u043A\u043B\u0438\u0435\u043D\u0442\u0430 \u0441 \u043D\u043E\u043C\u0435\u0440\u043E\u043C: ".concat(_vm.client.userPhone),
      "filter": {
        cleanPhone: _vm.client.cleanPhone,
        isDoubles: true
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.client && _vm.$helper.permission.canSeeCalls(_vm.user) ? _c('CallsGrid', {
    attrs: {
      "title": "\u041C\u0430\u043D\u0433\u043E: \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.client.userPhone),
      "filter": {
        clientPhone: _vm.client.cleanPhone
      }
    }
  }) : _vm._e(), _vm.client && _vm.$helper.permission.canSeeContractCalls(_vm.user) && _vm.client.userPhone ? _c('ContractCalls', {
    attrs: {
      "title": "\u041C\u0422\u0421: \u0438\u0441\u0445. \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.client.userPhone),
      "filter": {
        callingNumber: _vm.client.userPhone.replace(/\D+/g, '')
      }
    }
  }) : _vm._e(), _vm.client && _vm.$helper.permission.canSeeContractCalls(_vm.user) && _vm.client.userPhone ? _c('ContractCalls', {
    attrs: {
      "title": "\u041C\u0422\u0421: \u0432\u0445. \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.client.userPhone),
      "filter": {
        calledNumber: _vm.client.userPhone.replace(/\D+/g, '')
      }
    }
  }) : _vm._e(), _vm.client && _vm.client.id ? _c('ClientReport', {
    key: _vm.clientReportKey,
    staticClass: "mb-5",
    attrs: {
      "client-id": _vm.client.id,
      "contract-form": _vm.form,
      "defaultExpand": _vm.clientReportKey !== 0
    },
    on: {
      "updated": function updated($event) {
        _vm.clientReportKey++;
      }
    }
  }) : _vm._e(), _vm.client && _vm.client.id ? _c('ClientComments', {
    attrs: {
      "id": _vm.client.id
    }
  }) : _vm._e(), _vm.client && _vm.$helper.permission.canSeeContracts(_vm.user) ? _c('ContractGrid', {
    attrs: {
      "title": "Договоры",
      "filter": {
        client: _vm.client.id
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }