<template>
    <div>
        <v-list subheader>
            <v-subheader>План</v-subheader>
            <table class="table__plan v-data-table theme--dark">
                <tr style="background: #FF9800; color: #000">
                    <td>от 3-3,5</td>
                    <td>+ 3/1,5 %</td>
                </tr>
                <tr style="background: #FFEB3B; color: #000">
                    <td>от 3,5-4</td>
                    <td>+ 3,5/1,75 %</td>
                </tr>
                <tr style="background: #4CAF50; color: #000">
                    <td>от 4-4,5</td>
                    <td>+ 4/2 %</td>
                </tr>
                <tr style="background: #2196F3">
                    <td>от 4,5</td>
                    <td>+ 4,5/2,5 %</td>
                </tr>
            </table>
        </v-list>
    </div>
</template>

<script>

    export default {
        name: "LawyerPlan",
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {

        },
        created() {

        },
        beforeCreate() {

        }
    }
</script>

<style lang="scss" scoped>
    .table__plan {
        border-collapse: collapse;
        font-size: 14px;
        width: 100%;
        td {
            border: 1px solid #363636;
        }
    }
</style>
