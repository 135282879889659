<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="9">
                            <v-row v-if="request !== null">
                                <v-col>
                                    <v-text-field :value="form.id" disabled="disabled" label="ID"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                            v-model="form.state"
                                            :items="states"
                                            :disabled="!isManualDrivenState(form.state) && !$helper.permission.canChangeNotManualState(user, form.state)"
                                            v-on:change="onChangeState"
                                            label="Статус">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('state')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('state') }}
                                    </v-alert>
                                    <div v-if="request.appointmentDate">
                                        <v-row>
                                            <v-col>
                                                <v-text-field :value="request.appointmentDate" disabled></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                        :value="request.appointmentTime.text"
                                                        disabled>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col sm="auto">
                                    <AppointmentDialog
                                            v-if="$helper.permission.canMakeAppointments(user)"
                                            :id="form.id"
                                            :request="request"
                                            :isNewAppointment="!request.appointmentDate"
                                            :offices="page.offices"
                                            @saved="onAppointmentAdd">
                                    </AppointmentDialog>
                                </v-col>
                                <v-col sm="auto" v-if="request.appointmentDate && request.state !== 'CANCEL_ZAPIS'">
                                    <div v-if="$helper.permission.canMakeAppointments(user)">
                                        <v-btn outlined color="red" @click="cancelAppointment">
                                            <v-icon left>mdi-cancel</v-icon>
                                            Отменить запись
                                        </v-btn>
                                    </div>
                                    <div class="mt-3" v-if="!request.appointmentApproved">
                                        <v-btn outlined color="green" @click="approveAppointment">
                                            <v-icon left>mdi-check</v-icon>
                                            Подтвердить запись
                                        </v-btn>
                                    </div>
                                    <div class="mt-3" v-if="request.appointmentApproved">
                                        <v-btn outlined color="green" @click="cancelApproveAppointment">
                                            <v-icon small left>mdi-check</v-icon>
                                            Запись подтверждена
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="$helper.permission.canSeeSourceOfRequest(user) || !request">
                                    <v-autocomplete
                                            v-model="form.source"
                                            :items="sources"
                                            item-value="id"
                                            :disabled="request !== null"
                                            item-text="name"
                                            label="Источник"
                                            :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('source')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('source') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canChangeSubdivision(user)">
                                    <v-autocomplete
                                            v-model="form.subdivision"
                                            label="Подразделение"
                                            :items="subdivisions"
                                            item-value="id"
                                            item-text="name"
                                            :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('subdivision')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('subdivision') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canAssignRequest(user)">
                                    <v-autocomplete
                                            v-model="form.operator"
                                            label="Оператор"
                                            :items="operators"
                                            item-value="id"
                                            item-text="fullName"
                                            :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('operator')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('operator') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row v-if="request !== null">
                                <v-col v-if="$helper.permission.canSeeCreatedAtOfRequest(user)">
                                    <v-text-field
                                            :value="form.createdAt"
                                            disabled="disabled"
                                            label="Дата создания">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            :value="form.assignedAt"
                                            disabled="disabled"
                                            label="Дата привязки">
                                    </v-text-field>
                                </v-col>
                                <v-col v-if="$helper.permission.canAssignCounterpart(user)">
                                    <v-autocomplete
                                            v-model="form.counterpart"
                                            label="Контрагент"
                                            :items="counterparts"
                                            item-value="id"
                                            item-text="fullName"
                                            :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('counterpart')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('counterpart') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="form.userName" label="Имя"></v-text-field>
                                    <v-alert v-if="form.errors.has('userName')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('userName') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-row align="center" style="margin-top: -12px; margin-bottom: -12px">
                                        <v-col>
                                            <v-text-field
                                                    v-model="form.userPhone"
                                                    label="Телефон"
                                                    v-mask="'+7 (###) ###-##-## #'">
                                            </v-text-field>
                                            <v-alert v-if="form.errors.has('userPhone')" dense outlined type="error"
                                                     :icon="false">
                                                {{ form.errors.get('userPhone') }}
                                            </v-alert>
                                        </v-col>
                                        <v-col class="col-auto" v-if="form.userPhone">
                                            <a :href="`tel:${form.userPhone}`" style="text-decoration: none">
                                                <v-btn small outlined color="green">
                                                    <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                                </v-btn>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row align="center" style="margin-top: -12px; margin-bottom: -12px">
                                        <v-col>
                                            <v-text-field
                                                    v-model="form.additionalUserPhone"
                                                    label="Доп. телефон"
                                                    v-mask="'+7 (###) ###-##-## #'">
                                            </v-text-field>
                                            <v-alert v-if="form.errors.has('additionalUserPhone')" dense outlined
                                                     type="error" :icon="false">
                                                {{ form.errors.get('additionalUserPhone') }}
                                            </v-alert>
                                        </v-col>
                                        <v-col class="col-auto" v-if="form.additionalUserPhone">
                                            <a :href="`tel:${form.additionalUserPhone}`" style="text-decoration: none">
                                                <v-btn small outlined color="green">
                                                    <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                                </v-btn>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="form.userEmail" label="Email"></v-text-field>
                                    <v-alert v-if="form.errors.has('userEmail')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('userEmail') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="form.userIp">
                                    <v-text-field v-model="form.userIp" label="Ip" disabled=""></v-text-field>
                                    <v-alert v-if="form.errors.has('userIp')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('userIp') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                            v-model="form.area"
                                            :items="areas"
                                            item-value="id"
                                            item-text="name"
                                            label="Область права"
                                            :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('area')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('area') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                            v-model="form.city"
                                            :items="cities"
                                            item-value="id"
                                            item-text="name"
                                            label="Город"
                                            :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('city')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('city') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea auto-grow v-model="form.problem" label="Проблема"></v-textarea>
                                    <v-alert v-if="form.errors.has('problem')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('problem') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xl="3">

                        </v-col>
                    </v-row>
                    <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                        Исправьте ошибки
                    </v-alert>
                    <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                        {{ form.errors.get('error') }}
                    </v-alert>
                    <div v-show="!form.busy">
                        <v-btn large color="green" @click="request ? update(false) : save(false)">
                            Сохранить
                        </v-btn>
                        <v-btn large color="green" @click="request ? update(true) : save(false)" class="ml-4">
                            Сохранить и закрыть
                        </v-btn>
                    </div>
                    <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                </form>
            </v-card-text>
        </v-card>
        <div class="my-10">
            <RequestDoubles
                    v-if="request?.userPhone"
                    :title="`Заявки клиента с номером: ${request.userPhone}`"
                    :filter="{ cleanPhone: request.cleanPhone, isDoubles: true }"
                    :exclude="[request.id]">
            </RequestDoubles>
            <RequestDoubles
                    v-if="request?.additionalUserPhone"
                    :title="`Заявки клиента с номером: ${request.additionalUserPhone}`"
                    :filter="{ cleanPhone: request.cleanAdditionalUserPhone }"
                    :exclude="[request.id]">
            </RequestDoubles>
        </div>
        <CallsGrid
                v-if="request && $helper.permission.canSeeCalls(user)"
                :title="`Вызовы для номера: ${userPhones}`"
                :filter="{ clientPhones: [request.cleanPhone, request.cleanAdditionalUserPhone] }">
        </CallsGrid>
        <RequestComments v-if="request" :id="request.id"></RequestComments>
        <RequestAnswers v-if="request && $helper.permission.canSeeRequestAnswers(user)" :request="request"
                        :areas="areas"></RequestAnswers>
    </div>
</template>

<script>
import {Form} from '../_helpers/_form';
import RequestService from "../_services/RequestService";
import {mapState} from 'vuex';
import AppointmentDialog from "./appointments/AppointmentDialog";
import RequestComments from "./comments/RequestComments";
import CallsGrid from "../calls/CallsGrid";
import RequestAnswers from "./answers/RequestAnswers";

export default {
    name: "RequestForm",
    components: {
        RequestAnswers,
        CallsGrid, RequestDoubles: () => import('././RequestGrid'), RequestComments, AppointmentDialog
    },
    props: {
        page: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: this.page.request
                ? new Form(this.page.request)
                : new Form({}),
            sources: [],
            states: [], //only manual driven states are stored here
            allStates: [],
            operators: [],
            counterparts: [],
            subdivisions: [],
            request: this.page.request,
            areas: this.page.areas,
            cities: this.page.cities,
            loading: false
        }
    },
    methods: {
        fetch() {
            this.fetchSources();
            this.fetchStates();
            this.fetchOperators();
            this.fetchCounterparts();
            if (this.$helper.permission.canChangeSubdivision(this.user)) {
                this.fetchSubdivisions();
            }
        },
        fetchStates() {
            let data = this.page.states;
            this.allStates = data;
            data = data.filter(state => {
                return !(state.manualDriven !== 'true' && this.form.state !== state.value);
            });
            data = data.filter(state => {
                return !(!this.$helper.permission.canSetState(this.user, state) && this.form.state !== state.value);
            });
            this.states = data;
        },
        fetchSubdivisions() {
            this.subdivisions = this.page.subdivisions;
        },
        fetchSources() {
            this.sources = this.page.sources;
        },
        fetchOperators() {
            this.operators = this.page.operators;
        },
        fetchCounterparts() {
            this.counterparts = this.page.counterparts;
        },
        save() {
            RequestService.create(this.form).then(() => {
                this.$router.push('/');
            });
        },
        update(needToExit) {
            RequestService.update(this.request.id, this.form).then(() => {
                if (needToExit) {
                    this.$emit('closeAndUpdate');
                } else {
                    this.$emit('updated');
                }
            });
        },
        cancelAppointment() {
            RequestService.cancelAppointment(this.request.id).then(() => {
                this.$emit("closeAndUpdate");
            });
        },
        approveAppointment() {
            RequestService.approveAppointment(this.request.id).then(() => {
                this.request.appointmentApproved = true;
                this.$emit("fetchGrid");
            });
        },
        cancelApproveAppointment() {
            RequestService.cancelApproveAppointment(this.request.id).then(() => {
                this.request.appointmentApproved = false;
                this.$emit("fetchGrid");
            });
        },
        isManualDrivenState(requestState) {
            let state = this.allStates.find(v => v.value === requestState);
            return state.manualDriven === 'true' || state.value === 'NEW';
        },
        onAppointmentAdd() {
            this.$emit("closeAndUpdate");
        },
        onChangeState(state) {
            if (state === 'RECALL') {
                this.$emit('recallSelected')
            }
        }
    },
    computed: {
        ...mapState(['user']),
        userPhones() {
            return [this.request.userPhone, this.request.additionalUserPhone].filter(x => !!x).join(', ');
        }
    },
    created() {
        if (this.request && this.request.id) {
            this.$helper.saveViewedEntity('requests.viewed', this.request.id.toString(), this.$ls)
        }
        this.fetch();
    }
}
</script>

<style lang="scss">

</style>
