<template>
    <v-app>
        <component :is="layout">
            <transition mode="out-in" name="fade">
                <router-view></router-view>
            </transition>
        </component>
        <div class="flashpool">
            <flash-message></flash-message>
        </div>
    </v-app>
</template>

<script>
    import {mapState} from 'vuex';
    import SettingService from "@/_services/SettingService";
    import UserService from "@/_services/UserService";

    export default {
        data() {
            return {
                notificationAudio: new Audio(require('../_assets/sounds/notification.mp3'))
            }
        },
        computed: {
            ...mapState(['user', 'unMute', 'globalSettings']),
            settings: function() {
                return this.$store.state.settings
            },
            layout() {
                return this.$route.meta.layout || 'default-layout'
            }
        },
        methods: {
            getReminder(reminder) {
                let result = `Напоминание: ${reminder.start} <br> ${reminder.name} <br>`;
                if (reminder.description) {
                    result += ` ${reminder.description} <br>`;
                }
                if (reminder.request) {
                    result += `Заявка <a href="/requests/${reminder.request.id}/edit" class="linkTo">#${reminder.request.id}</a><br>`;
                }
                if (reminder.client) {
                    result += `Клиент <a href="/clients/${reminder.client.id}/edit" class="linkTo">#${reminder.client.id}</a><br>`;
                }
                if (reminder.contract) {
                    result += `Договор <a href="/contracts/${reminder.contract.id}/edit" class="linkTo">#${reminder.contract.id}</a><br>`;
                }
                return result;
            },
            playSound(audio) {
                if (this.unMute) {
                    audio.play();
                }
            },
            getRequestLink(requestId) {
                return `Новая заявка <a href="/requests/${requestId}/edit" class="linkTo">#${requestId}</a>`
            },
            getClientLink(clientId) {
                return `Новый клиент <a href="/clients/${clientId}/edit" class="linkTo">#${clientId}</a>`
            },
            getVisitLink(clientId) {
                return `Новый визит клиента: <a href="/clients/${clientId}/edit" class="linkTo">#${clientId}</a>`
            },
            getNotification(notification) {
                let msg = `<b style="font-size: 1.1em">${notification.title}</b> <br>`;
                msg += notification.subTitle ? `<b>${notification.subTitle}</b> <br>` : '';
                msg += `
                    <a
                        href="${notification.url}"
                        class="linkTo"
                    >
                        Открыть #${notification.id}
                    </a>
                    <br>
                    ${notification.body}
                `;
                if (notification.type) {
                    msg = `<b>${notification.type?.text}</b> <br> ${msg}`;
                }
                return msg;
            }
        },
        created() {
            this.$store.subscribeAction((action) => {
                console.log(this.settings.unNotify)
                if (!this.settings.unNotify) {
                    return;
                }
                if (action.type === 'newRequestReceived' && this.$helper.permission.canSeeNewRequestNotification(this.user)) {
                    this.playSound(this.notificationAudio);
                    this.flashSuccess(this.getRequestLink(action.payload.id));
                }
                if (action.type === 'newClientReceived' && this.$helper.permission.canSeeNewClientNotification(this.user)) {
                    this.playSound(this.notificationAudio);
                    this.flashSuccess(this.getClientLink(action.payload.id));
                }
                if (action.type === 'newVisitReceived') {
                    this.playSound(this.notificationAudio);
                    this.flashSuccess(this.getVisitLink(action.payload.client.id));
                }
                if (action.type === 'remindReceived') {
                    this.playSound(this.notificationAudio);
                    this.flashInfo(this.getReminder(action.payload));
                }
                if (action.type === 'notificationReceived') {
                    this.playSound(this.notificationAudio);
                    this.flash(this.getNotification(action.payload), action.payload.colorClass);
                }
            });
            SettingService.getGlobal().then(data => {
                this.$store.state.globalSettings = data;
                console.log(`Settings were fetched: ${data}`);
            });
            UserService.getCurrent().then(data => {
                this.$store.state.user = data;
                console.log(`Current user was fetched`);
            });
        },
        mounted() {
            document.addEventListener("click", e => {
                if (e.target.classList.contains('linkTo')) {
                    e.preventDefault();
                    this.$router.push(e.target.getAttribute("href"));
                }
            });
        },
    }
</script>

<style lang="scss">
    body {
        font-family: "Roboto", sans-serif;
    }
    header, .v-toolbar__content {
        height: 44px !important;
    }
    .button-close-edit {
        display: none;
    }
    .v-dialog__content {
        .button-close-edit {
            display: block;
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .1s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }

    form {
        .v-alert.error--text {
            font-size: 12px;
            padding: 5px 0;
            line-height: 1;
            margin: -18px 0 18px;
            border: none !important;
        }
    }

    .flashpool {
        position: fixed;
        top: 20px;
        right: 20px;
        max-height: 400px;
        width: 320px;
        -webkit-perspective: 400px;
        perspective: 400px;
        z-index: 219;
    }

    .flash__message a {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .flash__message {
        color: #ffffff !important;
        font-size: 13px;
    }

    .flash__message.error {
        color: #fff !important;
    }

    .v-application .flash__message.info {
        background-color: #6a1b9a !important;
        border-color: #6a1b9a !important;
    }

    .v-dialog--fullscreen {
        background: #121212;
    }

    .v-btn.active .v-icon {
        transform: rotate(360deg);
    }

    .v-data-table td, .v-data-table th {
        padding: 10px 16px !important;
    }

    .v-data-table td a {
        text-decoration: none;
    }

    .v-data-table tr.v-data-table__progress th {
        padding: 0 !important;
    }

    tr.row_sticky td {
        position: -webkit-sticky;
        position: sticky;
        top: 48px;
        z-index: 3;
        background: #1e1e1e;
    }

    .v-dialog--fullscreen {
        &.v-dialog--animated {
            animation: none;
        }
    }

    .table-footer-prepend {
        margin-top: -58px;
        height: 58px;
    }

    .chekbox__yellow {
        .v-input--selection-controls__input .v-icon {
            color: #ffeb3b !important;
        }
        label {
            color: #ffeb3b !important;
        }
    }
    .chekbox__orange {
        .v-input--selection-controls__input .v-icon {
            color: #ff5722 !important;
        }
        label {
            color: #ff5722 !important;
        }
    }
    .chekbox__blue {
        .v-input--selection-controls__input .v-icon {
            color: #039BE5 !important;
        }
        label {
            color: #039BE5 !important;
        }
    }
    .chekbox__green {
        .v-input--selection-controls__input .v-icon {
            color: #4CAF50 !important;
        }
        label {
            color: #4CAF50 !important;
        }
    }
    .chekbox__amber {
        .v-input--selection-controls__input .v-icon {
            color: #FFE082 !important;
        }
        label {
            color: #FFE082 !important;
        }
    }
    .chekbox__white {
        .v-input--selection-controls__input .v-icon {
            color: #FFF !important;
        }
        label {
            color: #FFF !important;
        }
    }
    tr.result-row td:nth-child(n+1) {
        font-weight: 700;
        font-size: .875rem !important;
    }
    .v-data-footer {
        justify-content: flex-start !important;
        padding: 0 8px 0 17px !important;
    }
    .v-data-footer__select {
        margin-left: 14px !important;
    }
    .field-info {
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
        margin-top: -15px;
        margin: 7px 0;
    }
    .table-action {
        padding-left: 460px !important;
    }
    .files {
        .v-chip {
            a {
                margin: 0 10px 0 0;
                color: #fff;
            }
            .v-progress-circular {
                margin-right: 10px;
            }
            .mdi-close {
                margin: 0 7px 0 0 !important;
            }
        }
    }
    .fileDirectories {
        margin-top: 1em;
        & > div {
            margin: 1.2em 0;
        }
        .files {
            margin: 0.85em 0;
        }
    }
    .directory-name,
    .directory-name .v-chip {
        cursor: pointer;
    }
    tr.lightgrey {
        opacity: 1;
    }
    .v-data-table {
        pre {
            font-size: 1.1em;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
            font-family: inherit;
        }
        .mdi-star,
        .mdi-star-outline {
            font-size: 19px !important;
        }
    }
    .v-chip__content .v-input__slot {
        margin-bottom: 5px;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        border-bottom: thin solid rgb(255 255 255/ 18%) !important;
    }
    .table__lined {
        background-color: transparent !important;
        table {
            border-collapse: collapse;
            th, td {
                border: thin solid rgba(255, 255, 255, 0.36) !important;
                @media (min-width: 768px) {
                    font-size: 1.15em !important;
                }
            }
            th {
                position: sticky;
                top: -1px;
                z-index: 3 !important;
                background: #388E3C;
                color: #fff !important;
                border: 2px solid #021803 !important;
                &:first-child {
                    z-index: 4 !important;
                }
            }
            th:first-child,
            td:first-child {
                position: sticky;
                left: -1px;
                z-index: 2;
                border-left: none !important;
            }
            td:first-child {
                background: #1e1e1e;
            }
            .result-row {
                td {
                    position: sticky;
                    bottom: -1px;
                    background: #2E7D32;
                    color: #fff;
                    border: 2px solid #022102 !important;
                    font-size: 1.2em !important;
                }
            }
        }
        .v-data-table__wrapper {
            overflow: visible !important;
        }
    }
    .v-card__title {
        position: relative;
        z-index: 9;
    }
    .v-application .v-app-bar.v-app-bar--fixed {
        z-index: 10;
    }
    .revision_old_value {
        border: 1px solid #e3b570;
        border-radius: 10px;
        padding: 2px 10px;
        margin: 10px 0 0;
        color: #e3b570;
        font-size: 11px;
    }
    .revision-changes {
        span {
            opacity: 0.6;
            font-size: .85em;
        }
        table {

        }
        td {
            padding: 0.1em 0.7em !important;
        }
    }
    .v-select--is-multi {
        .v-select__selections {
            max-height: 32px;
            overflow-y: hidden;
        }
    }
    .theme--dark.v-input--is-disabled input,
    .theme--dark.v-input--is-disabled textarea {
        color: rgba(255, 255, 255, 0.75);
    }
    .menuable__content__active {
        margin-top: 33px;
    }
    .font-size__small {
        font-size: 0.9em;
    }
    .flash__message-content-body {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }
</style>
