var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "8"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": "Активен"
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }), _vm.form.errors.has('active') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('active')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Имя"
    },
    model: {
      value: _vm.form.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "firstName", $$v);
      },
      expression: "form.firstName"
    }
  }), _vm.form.errors.has('firstName') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('firstName')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Фамилия"
    },
    model: {
      value: _vm.form.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lastName", $$v);
      },
      expression: "form.lastName"
    }
  }), _vm.form.errors.has('lastName') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lastName')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "disabled": _vm.user != null
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm.form.errors.has('email') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('email')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-##',
      expression: "'+7 (###) ###-##-##'"
    }],
    attrs: {
      "label": "Телефон"
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _vm.form.errors.has('phone') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('phone')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('DateField', {
    attrs: {
      "label": "Дата рождения"
    },
    model: {
      value: _vm.form.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "birthday", $$v);
      },
      expression: "form.birthday"
    }
  }), _vm.form.errors.has('birthday') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('birthday')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_vm._l(_vm.form.innerPhoneNumbers, function (innerPhoneNumber, index) {
    return _c('v-col', {
      key: index
    }, [_c('v-text-field', {
      attrs: {
        "label": "Внутренний номер"
      },
      model: {
        value: innerPhoneNumber.number,
        callback: function callback($$v) {
          _vm.$set(innerPhoneNumber, "number", $$v);
        },
        expression: "innerPhoneNumber.number"
      }
    }), _vm.form.errors.has('innerPhoneNumbers[' + index + '].number') ? _c('v-alert', {
      attrs: {
        "dense": "",
        "outlined": "",
        "type": "error",
        "icon": false
      }
    }, [_vm._v(" " + _vm._s(_vm.form.errors.get('innerPhoneNumbers[' + index + '].number')) + " ")]) : _vm._e()], 1);
  }), _c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": _vm.addInnerPhoneNumber
    }
  }, [_vm.form.innerPhoneNumbers.length < 1 ? _c('span', [_vm._v("добавить внутренний номер")]) : _vm._e(), _vm.form.innerPhoneNumbers.length > 0 ? _c('span', [_vm._v("добавить")]) : _vm._e()])], 1), _vm.form.innerPhoneNumbers.length > 0 ? _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "red"
    },
    on: {
      "click": _vm.deleteInnerPhoneNumber
    }
  }, [_vm._v(" удалить ")])], 1) : _vm._e()], 1)], 1)], 2), _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" * Телефон должен быть уникальным для каждого пользователя ")]), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "prepend-icon": "mdi-account-lock-outline",
      "label": "Роль",
      "items": _vm.roles
    },
    model: {
      value: _vm.form.role,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role", $$v);
      },
      expression: "form.role"
    }
  }), _vm.form.errors.has('role') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('role')) + " ")]) : _vm._e()], 1)], 1), _vm.form.role === 'LAWYER' ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "План (юрист)"
    },
    model: {
      value: _vm.form.lawyerPlan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerPlan", $$v);
      },
      expression: "form.lawyerPlan"
    }
  }), _vm.form.errors.has('lawyerPlan') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerPlan')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.role === 'OPERATOR' ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "step": "0.1",
      "label": "План (орк)"
    },
    model: {
      value: _vm.form.orkPlan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "orkPlan", $$v);
      },
      expression: "form.orkPlan"
    }
  }), _vm.form.errors.has('orkPlan') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('orkPlan')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "prepend-icon": "mdi-account-group",
      "label": "Орг. отдел",
      "items": _vm.subdivisions,
      "item-text": "name",
      "item-value": "id",
      "clearable": ""
    },
    model: {
      value: _vm.form.subdivision,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subdivision", $$v);
      },
      expression: "form.subdivision"
    }
  }), _vm.form.errors.has('subdivision') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('subdivision')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "prepend-icon": "mdi-office-building",
      "label": "Офис",
      "items": _vm.offices,
      "item-text": "name",
      "item-value": "id",
      "clearable": ""
    },
    model: {
      value: _vm.form.office,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "office", $$v);
      },
      expression: "form.office"
    }
  }), _vm.form.errors.has('office') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('office')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "clearable": "",
      "append-icon": "mdi-dice-multiple-outline",
      "prepend-icon": "mdi-account-key-outline",
      "label": "Новый пароль"
    },
    on: {
      "click:append": _vm.generatePass
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _vm.form.errors.has('password') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('password')) + " ")]) : _vm._e()], 1)], 1), _vm.$helper.env.isTrue('VUE_APP_CAN_PUBLISH_ON_SITE') ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Id юриста на портале"
    },
    model: {
      value: _vm.form.portalLawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "portalLawyer", $$v);
      },
      expression: "form.portalLawyer"
    }
  }), _vm.form.errors.has('portalLawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('portalLawyer')) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.user ? _vm.update(false) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }