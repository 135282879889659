var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "overflow-x": "scroll"
    }
  }, [!_vm.title ? _c('v-card-title', [_vm._v(" База договоров "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.forceUpdate
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer'), _c('div', [_vm.$helper.permission.canCreateContract(_vm.user) ? _c('v-btn', {
    attrs: {
      "color": "green",
      "to": "/contracts/create"
    }
  }, [_vm._v(" Добавить договор ")]) : _vm._e(), _vm.$helper.permission.canDeleteContract(_vm.user) ? _c('v-btn', {
    staticClass: "ml-3",
    attrs: {
      "outlined": "",
      "loading": _vm.recalculatePayedAmountLoading,
      "color": "orange"
    },
    on: {
      "click": _vm.recalculatePayedAmount
    }
  }, [_vm._v(" Пересчитать остатки ")]) : _vm._e(), _c('v-menu', {
    attrs: {
      "max-height": "300",
      "bottom": "",
      "close-on-content-click": false,
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "mx-4",
          attrs: {
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-filter-cog-outline")])], 1)];
      }
    }], null, false, 2995181120)
  }, [_c('v-list', [_c('v-list-item-group', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.selectedHeaders,
      callback: function callback($$v) {
        _vm.selectedHeaders = $$v;
      },
      expression: "selectedHeaders"
    }
  }, _vm._l(_vm.headers, function (header, index) {
    return _c('v-list-item', {
      key: header.value,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var active = _ref2.active,
            toggle = _ref2.toggle;
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(header.text) + " ")])], 1)];
        }
      }], null, true)
    });
  }), 1)], 1), _c('v-list', [_c('v-list-item-group', [_c('v-list-item', {
    on: {
      "click": _vm.resetSelectedHeaders
    }
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "color": "red",
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-filter-off")])], 1), _vm._v(" Сбросить ")], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    style: "min-width: ".concat(_vm.getTableWidth()),
    attrs: {
      "headers": _vm.visibleHeaders,
      "items": _vm.contracts,
      "options": _vm.contractGridsOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': _vm.itemPerPage
      },
      "fixed-header": _vm.fixedHeader,
      "height": _vm.tableHeight
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.contractGridsOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref3) {
        var items = _ref3.items;
        return [_c('tbody', [!_vm.filter ? _c('tr', {
          staticClass: "row_sticky"
        }, [_vm.isVisibleColumn('action') ? _c('td', {
          staticClass: "px-2",
          attrs: {
            "width": _vm.getColumnWidthOrDefault('action', 30)
          }
        }) : _vm._e(), _vm.isVisibleColumn('inFavorites') ? _c('td', {
          staticClass: "pr-0",
          attrs: {
            "width": _vm.getColumnWidthOrDefault('inFavorites', 30)
          }
        }, [_c('v-checkbox', {
          staticClass: "ma-0",
          attrs: {
            "value": _vm.user.id,
            "hide-details": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter['usersAddToFavorites[0].id'],
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, 'usersAddToFavorites[0].id', $$v);
            },
            expression: "contractGridsOptions.filter['usersAddToFavorites[0].id']"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('id') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('id', 110)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "id", $$v);
            },
            expression: "contractGridsOptions.filter.id"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('number') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('number', 120)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.number,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "number", $$v);
            },
            expression: "contractGridsOptions.filter.number"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('state') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('state', 160)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.states,
            "clearable": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter.states,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "states", $$v);
            },
            expression: "contractGridsOptions.filter.states"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('phase') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('phase', 160)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.phases,
            "clearable": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter.phases,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "phases", $$v);
            },
            expression: "contractGridsOptions.filter.phases"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('userName') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('userName', 100)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.userName,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "userName", $$v);
            },
            expression: "contractGridsOptions.filter.userName"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('principal') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('principal', 160)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.principal,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "principal", $$v);
            },
            expression: "contractGridsOptions.filter.principal"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('sentToOkkActions') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('sentToOkkActions', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.sentToOkkActionsDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "sentToOkkActionsDates", $$v);
            },
            expression: "contractGridsOptions.filter.sentToOkkActionsDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('flow.applicationDate') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('flow.applicationDate', 200)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.flow.applicationDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter.flow, "applicationDates", $$v);
            },
            expression: "contractGridsOptions.filter.flow.applicationDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('flow.applicationDate') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('flow.answerDate', 200)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.flow.answerDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter.flow, "answerDates", $$v);
            },
            expression: "contractGridsOptions.filter.flow.answerDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('flow.applicationDate') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('flow.moneyBackDate', 200)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.flow.moneyBackDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter.flow, "moneyBackDates", $$v);
            },
            expression: "contractGridsOptions.filter.flow.moneyBackDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('createdAt') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('createdAt', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter.createdAtDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "createdAtDates", $$v);
            },
            expression: "contractGridsOptions.filter.createdAtDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('date') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('date', 170)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter.dates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "dates", $$v);
            },
            expression: "contractGridsOptions.filter.dates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('amount') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('amount', 130)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.amount,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "amount", $$v);
            },
            expression: "contractGridsOptions.filter.amount"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('alreadyPayed') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('alreadyPayed', 130)
          }
        }) : _vm._e(), _vm.isVisibleColumn('earnedAmount') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('earnedAmount', 130)
          }
        }) : _vm._e(), _vm.isVisibleColumn('additionalPayments') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('additionalPayments', 200)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter.additionalPaymentsDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "additionalPaymentsDates", $$v);
            },
            expression: "contractGridsOptions.filter.additionalPaymentsDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('payedAmount') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('payedAmount', 130)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.payedAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "payedAmount", $$v);
            },
            expression: "contractGridsOptions.filter.payedAmount"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('vozvrat') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('vozvrat', 200)
          }
        }) : _vm._e(), _vm.isVisibleColumn('type') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('type', 100)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.types,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.types,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "types", $$v);
            },
            expression: "contractGridsOptions.filter.types"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('subject') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('subject', 100)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.subjects,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.subjects,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "subjects", $$v);
            },
            expression: "contractGridsOptions.filter.subjects"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('courtDate') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('courtDate', 170)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": true,
            "clearable": ""
          },
          model: {
            value: _vm.contractGridsOptions.filter.courtDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "courtDates", $$v);
            },
            expression: "contractGridsOptions.filter.courtDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('sentToOKKBy') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('sentToOKKBy', 260)
          }
        }) : _vm._e(), _vm.isVisibleColumn('lawyerOGPA') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('lawyerOGPA', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyersOGPA,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.lawyerOGPAs,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "lawyerOGPAs", $$v);
            },
            expression: "contractGridsOptions.filter.lawyerOGPAs"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('isPaymentOGPA') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('isPaymentOGPA', 140)
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.isPaymentOGPA,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "isPaymentOGPA", $$v);
            },
            expression: "contractGridsOptions.filter.isPaymentOGPA"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('additionalLawyerOGPA') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('additionalLawyerOGPA', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyersOGPA,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.additionalLawyerOGPAs,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "additionalLawyerOGPAs", $$v);
            },
            expression: "contractGridsOptions.filter.additionalLawyerOGPAs"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('lawyerOGPAAssistant') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('lawyerOGPAAssistant', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.lawyersOGPA,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.lawyerOGPAAssistant,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "lawyerOGPAAssistant", $$v);
            },
            expression: "contractGridsOptions.filter.lawyerOGPAAssistant"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('actPaymentRemainder') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('actPaymentRemainder', 200)
          }
        }) : _vm._e(), _vm.isVisibleColumn('lawyer') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('lawyer', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.lawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "lawyers", $$v);
            },
            expression: "contractGridsOptions.filter.lawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('additionalLawyer') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('additionalLawyer', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.additionalLawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "additionalLawyers", $$v);
            },
            expression: "contractGridsOptions.filter.additionalLawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('lawyerAssistant') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('lawyerAssistant', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.lawyerAssistant,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "lawyerAssistant", $$v);
            },
            expression: "contractGridsOptions.filter.lawyerAssistant"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('isBezNal') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('isBezNal', 80)
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.isBezNal,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "isBezNal", $$v);
            },
            expression: "contractGridsOptions.filter.isBezNal"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('lawyerEPO') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('lawyerEPO', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyersEPO,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.lawyerEPOs,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "lawyerEPOs", $$v);
            },
            expression: "contractGridsOptions.filter.lawyerEPOs"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('additionalLawyerEPO') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('additionalLawyerEPO', 260)
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.lawyersEPO,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractGridsOptions.filter.additionalLawyerEPO,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "additionalLawyerEPO", $$v);
            },
            expression: "contractGridsOptions.filter.additionalLawyerEPO"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('transport') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('transport', 110)
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractGridsOptions.filter.transport,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "transport", $$v);
            },
            expression: "contractGridsOptions.filter.transport"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('sentForDocumentsPreparationAt') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('sentForDocumentsPreparationAt', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.sentForDocumentsDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "sentForDocumentsDates", $$v);
            },
            expression: "contractGridsOptions.filter.sentForDocumentsDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('taskLegalExpert.readyDate') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('taskLegalExpert.readyDate', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": false
          },
          model: {
            value: _vm.contractGridsOptions.filter.taskLegalExpert.readyDate,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter.taskLegalExpert, "readyDate", $$v);
            },
            expression: "contractGridsOptions.filter.taskLegalExpert.readyDate"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('taskAddLegalExpert.readyDate') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('taskAddLegalExpert.readyDate', 240)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": false
          },
          model: {
            value: _vm.contractGridsOptions.filter.taskAddLegalExpert.readyDate,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter.taskAddLegalExpert, "readyDate", $$v);
            },
            expression: "contractGridsOptions.filter.taskAddLegalExpert.readyDate"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('documentsAreReadyAt') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('documentsAreReadyAt', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.documentsAreReadyDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "documentsAreReadyDates", $$v);
            },
            expression: "contractGridsOptions.filter.documentsAreReadyDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('transferredToCourtActions') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('transferredToCourtActions', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.transferredToCourtActionsDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "transferredToCourtActionsDates", $$v);
            },
            expression: "contractGridsOptions.filter.transferredToCourtActionsDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('sentToCourtActions') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('sentToCourtActions', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.sentToCourtActionsDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "sentToCourtActionsDates", $$v);
            },
            expression: "contractGridsOptions.filter.sentToCourtActionsDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('transferredToOGPAAt') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('transferredToOGPAAt', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.transferredToOGPADates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "transferredToOGPADates", $$v);
            },
            expression: "contractGridsOptions.filter.transferredToOGPADates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('packageFromLenyrActions') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('packageFromLenyrActions', 220)
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.contractGridsOptions.filter.packageFromLenyrActionsDates,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "packageFromLenyrActionsDates", $$v);
            },
            expression: "contractGridsOptions.filter.packageFromLenyrActionsDates"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('area') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('area', 160)
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.areas,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.contractGridsOptions.filter.area,
            callback: function callback($$v) {
              _vm.$set(_vm.contractGridsOptions.filter, "area", $$v);
            },
            expression: "contractGridsOptions.filter.area"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('comment', 230)
          }
        }) : _vm._e(), _vm.isVisibleColumn('commentForBuhgalter') ? _c('td', {
          attrs: {
            "width": _vm.getColumnWidthOrDefault('commentForBuhgalter', 230)
          }
        }) : _vm._e()]) : _vm._e(), _vm._l(items, function (item, key) {
          return _c('tr', {
            key: item.id,
            class: {
              'grey darken-3': _vm.isHighlighted(item)
            }
          }, [_vm.isVisibleColumn('action') ? _c('td', {
            staticClass: "pl-5 pr-3"
          }, [_vm.$helper.permission.canDeleteContract(_vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref4) {
                var on = _ref4.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('inFavorites') ? _c('td', [item.inFavorites ? _c('v-icon', {
            staticClass: "ml-1",
            attrs: {
              "color": "yellow"
            },
            on: {
              "click": function click($event) {
                return _vm.removeFromFavorites(item);
              }
            }
          }, [_vm._v(" mdi-star ")]) : _vm._e(), !item.inFavorites ? _c('v-icon', {
            staticClass: "ml-1",
            attrs: {
              "color": "grey"
            },
            on: {
              "click": function click($event) {
                return _vm.addToFavorites(item);
              }
            }
          }, [_vm._v(" mdi-star-outline ")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('id') ? _c('td', [_c('a', {
            staticStyle: {
              "padding": "20px"
            },
            on: {
              "click": function click($event) {
                return _vm.editContract(item.id, items[key - 1], items[key + 1]);
              }
            }
          }, [_vm._v(" " + _vm._s(item.id) + " ")])]) : _vm._e(), _vm.isVisibleColumn('number') ? _c('td', [item.number ? _c('div', [_vm._v(_vm._s(item.number))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('state') ? _c('td', [item.state ? _c('v-chip', {
            attrs: {
              "color": item.state.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" " + _vm._s(item.state.text) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('phase') ? _c('td', [item.phase ? _c('v-chip', {
            attrs: {
              "color": item.phase.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" " + _vm._s(item.phase.text) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('userName') ? _c('td', [item.userName ? _c('div', [_vm._v(_vm._s(item.userName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('principal') ? _c('td', [item.principal ? _c('div', [_vm._v(_vm._s(item.principal))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('sentToOkkActions') ? _c('td', [item.sentToOkkActions ? _c('div', _vm._l(item.sentToOkkActions, function (sentToOkkAction) {
            return _c('div', {
              key: sentToOkkAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(sentToOkkAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('flow.applicationDate') ? _c('td', [item.contractFlows ? _c('div', {
            staticClass: "text-caption"
          }, _vm._l(item.contractFlows, function (flow) {
            return _c('div', {
              key: flow.id
            }, [flow.applicationDate ? _c('div', {
              staticClass: "mt-1"
            }, [flow.user ? _c('div', [_vm._v(_vm._s(flow.user.fullName))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$moment(flow.applicationDate).dateFormat()) + " "), flow.otherApplication ? _c('span', [_vm._v("Иное заявление")]) : _vm._e(), _c('v-divider', {
              staticClass: "my-1"
            })], 1) : _vm._e()]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('flow.applicationDate') ? _c('td', [item.contractFlows ? _c('div', {
            staticClass: "text-caption"
          }, _vm._l(item.contractFlows, function (flow) {
            return _c('div', {
              key: flow.id
            }, [flow.answerDate ? _c('div', {
              staticClass: "mt-1"
            }, [flow.user ? _c('div', [_vm._v(_vm._s(flow.user.fullName))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$moment(flow.answerDate).dateFormat()) + " "), flow.otherApplication ? _c('span', [_vm._v("Иное заявление")]) : _vm._e(), _c('v-divider', {
              staticClass: "my-1"
            })], 1) : _vm._e()]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('flow.applicationDate') ? _c('td', [item.contractFlows ? _c('div', {
            staticClass: "text-caption"
          }, _vm._l(item.contractFlows, function (flow) {
            return _c('div', {
              key: flow.id
            }, [flow.moneyBackDate ? _c('div', {
              staticClass: "mt-1"
            }, [flow.user ? _c('div', [_vm._v(_vm._s(flow.user.fullName))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$moment(flow.moneyBackDate).dateFormat()) + " "), flow.otherApplication ? _c('span', [_vm._v("Иное заявление")]) : _vm._e(), _c('v-divider', {
              staticClass: "my-1"
            })], 1) : _vm._e()]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('createdAt') ? _c('td', [_vm._v(_vm._s(_vm.$moment(item.createdAt).dateTimeFormat()))]) : _vm._e(), _vm.isVisibleColumn('date') ? _c('td', [_vm._v(_vm._s(_vm.$moment(item.date).dateFormat()))]) : _vm._e(), _vm.isVisibleColumn('amount') ? _c('td', [item.amount ? _c('div', [_vm._v(_vm._s(item.amount))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('alreadyPayed') ? _c('td', [_c('div', [_vm._v(_vm._s(item.payedAmount))])]) : _vm._e(), _vm.isVisibleColumn('earnedAmount') ? _c('td', [_c('div', [_vm._v(_vm._s(item.earnedAmount))])]) : _vm._e(), _vm.isVisibleColumn('additionalPayments') ? _c('td', [item.additionalPayments ? _c('div', _vm._l(item.additionalPayments, function (additionalPayment) {
            return _c('div', {
              key: additionalPayment.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(additionalPayment.date) + ": " + _vm._s(additionalPayment.amount) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('payedAmount') ? _c('td', [_c('div', [_vm._v(_vm._s(item.amount - item.payedAmount))])]) : _vm._e(), _vm.isVisibleColumn('vozvrat') ? _c('td', [_vm.getContractVozvrats(item.payments) ? _c('div', {
            staticClass: "text-caption"
          }, _vm._l(_vm.getContractVozvrats(item.payments), function (payment) {
            return _c('div', {
              key: payment.id,
              staticClass: "mt-1"
            }, [_c('div', [_vm._v(_vm._s(payment.date))]), _vm._v(" " + _vm._s(payment.amount) + " "), _c('v-divider', {
              staticClass: "my-1"
            })], 1);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('type') ? _c('td', [item.type ? _c('div', [_vm._v(_vm._s(_vm.getTypeName(item.type)))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('subject') ? _c('td', [item.subject ? _c('div', [_vm._v(_vm._s(_vm.getSubjectName(item.subject)))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('courtDate') ? _c('td', [_vm._v(" " + _vm._s(_vm.$moment(item.courtDateTime).dateTimeFormat()) + " ")]) : _vm._e(), _vm.isVisibleColumn('sentToOKKBy') ? _c('td', [item.sentToOKKBy ? _c('div', [_vm._v(_vm._s(item.sentToOKKBy.fullName))]) : _vm._e(), item.sentToOKKDate ? _c('div', [_vm._v(_vm._s(_vm.$moment(item.sentToOKKDate).dateFormat()))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('lawyerOGPA') ? _c('td', [item.lawyerOGPA ? _c('div', [_vm._v(" " + _vm._s(item.lawyerOGPA.fullName) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('isPaymentOGPA') ? _c('td', [item.isPaymentOGPA ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "green"
            }
          }, [_vm._v("mdi-check")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('additionalLawyerOGPA') ? _c('td', [item.additionalLawyerOGPA ? _c('div', [_vm._v(" " + _vm._s(item.additionalLawyerOGPA.fullName) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('lawyerOGPAAssistant') ? _c('td', [item.lawyerOGPAAssistant ? _c('div', [_vm._v(_vm._s(item.lawyerOGPAAssistant.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('actPaymentRemainder') ? _c('td', [_vm._v(" " + _vm._s(_vm.getActRemainder(item)) + " ")]) : _vm._e(), _vm.isVisibleColumn('lawyer') ? _c('td', [item.lawyer ? _c('div', [_vm._v(_vm._s(item.lawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('additionalLawyer') ? _c('td', [item.additionalLawyer ? _c('div', [_vm._v(_vm._s(item.additionalLawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('lawyerAssistant') ? _c('td', [item.lawyerAssistant ? _c('div', [_vm._v(_vm._s(item.lawyerAssistant.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('isBezNal') ? _c('td', [item.isBezNal ? _c('v-chip', {
            attrs: {
              "color": "light-blue",
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v("Безнал")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('lawyerEPO') ? _c('td', [item.lawyerEPO ? _c('div', [_vm._v(_vm._s(item.lawyerEPO.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('additionalLawyerEPO') ? _c('td', [item.additionalLawyerEPO ? _c('div', [_vm._v(_vm._s(item.additionalLawyerEPO.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('transport') ? _c('td', [item.transport ? _c('div', [_vm._v(_vm._s(item.transport))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('sentForDocumentsPreparationAt') ? _c('td', [item.sentForDocumentsActions ? _c('div', _vm._l(item.sentForDocumentsActions, function (sentForDocumentsAction) {
            return _c('div', {
              key: sentForDocumentsAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(sentForDocumentsAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('taskLegalExpert.readyDate') ? _c('td', [item.taskLegalExpert.readyDate ? _c('div', [_vm._v(" " + _vm._s(_vm.$moment(item.taskLegalExpert.readyDate).dateFormat()) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('taskAddLegalExpert.readyDate') ? _c('td', [item.taskAddLegalExpert.readyDate ? _c('div', [_vm._v(" " + _vm._s(_vm.$moment(item.taskAddLegalExpert.readyDate).dateFormat()) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('documentsAreReadyAt') ? _c('td', [item.documentsAreReadyActions ? _c('div', _vm._l(item.documentsAreReadyActions, function (documentsAreReadyAction) {
            return _c('div', {
              key: documentsAreReadyAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(documentsAreReadyAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('transferredToCourtActions') ? _c('td', [item.transferredToCourtActions ? _c('div', _vm._l(item.transferredToCourtActions, function (transferredToCourtAction) {
            return _c('div', {
              key: transferredToCourtAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(transferredToCourtAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('sentToCourtActions') ? _c('td', [item.sentToCourtActions ? _c('div', _vm._l(item.sentToCourtActions, function (sentToCourtAction) {
            return _c('div', {
              key: sentToCourtAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(sentToCourtAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('transferredToOGPAAt') ? _c('td', [item.transferredToOGPAActions ? _c('div', _vm._l(item.transferredToOGPAActions, function (transferredToOGPAAction) {
            return _c('div', {
              key: transferredToOGPAAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(transferredToOGPAAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('packageFromLenyrActions') ? _c('td', [item.packageFromLenyrActions ? _c('div', _vm._l(item.packageFromLenyrActions, function (packageFromLenyrAction) {
            return _c('div', {
              key: packageFromLenyrAction.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(_vm.$moment(packageFromLenyrAction.createdAt).dateTimeFormat()) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('area') ? _c('td', [item.area ? _c('div', [_vm._v(_vm._s(item.area.name))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', [item.comment ? _c('div', [_vm._v(_vm._s(item.comment))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('commentForBuhgalter') ? _c('td', [item.commentForBuhgalter ? _c('div', [_vm._v(_vm._s(item.commentForBuhgalter))]) : _vm._e()]) : _vm._e()]);
        }), _c('tr', {
          staticClass: "result-row"
        }, _vm._l(_vm.visibleHeaders, function (header, i) {
          return _c('td', {
            key: i
          }, [i === 3 ? _c('div', [_vm._v("Всего:")]) : _vm._e(), header.value == 'amount' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalAmount)) + " ")]) : _vm._e(), header.value == 'alreadyPayed' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalAlreadyPayedAmount)) + " ")]) : _vm._e(), header.value == 'payedAmount' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalPayedAmount)) + " ")]) : _vm._e(), header.value == 'vozvrat' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalVozvrat)) + " ")]) : _vm._e(), header.value == 'actPaymentRemainder' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalActPaymentRemainder)) + " ")]) : _vm._e(), header.value == 'transport' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalTransport)) + " ")]) : _vm._e()]);
        }), 0)], 2)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('v-bottom-sheet', {
    attrs: {
      "fullscreen": "",
      "scrollable": "",
      "persistent": "",
      "retain-focus": false
    },
    model: {
      value: _vm.dialog.contractEdit.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.contractEdit, "modal", $$v);
      },
      expression: "dialog.contractEdit.modal"
    }
  }, [_c('v-card', {
    staticStyle: {
      "background": "none"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_vm.editedContract ? _c('ContractUpdatePage', {
    key: _vm.editedContractKey,
    attrs: {
      "id": _vm.editedContract,
      "inPopup": true,
      "nextContract": _vm.nextContract,
      "prevContract": _vm.prevContract
    },
    on: {
      "changeEdited": _vm.changeEdited,
      "updated": _vm.forceUpdate,
      "closeAndUpdate": _vm.closeEditAndFetch,
      "closeEdit": _vm.closeEdit
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }