var render = function () {
  var _vm$request, _vm$request2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_vm.request !== null ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.id,
      "disabled": "disabled",
      "label": "ID"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.states,
      "disabled": !_vm.isManualDrivenState(_vm.form.state) && !_vm.$helper.permission.canChangeNotManualState(_vm.user, _vm.form.state),
      "label": "Статус"
    },
    on: {
      "change": _vm.onChangeState
    },
    model: {
      value: _vm.form.state,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "state", $$v);
      },
      expression: "form.state"
    }
  }), _vm.form.errors.has('state') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('state')) + " ")]) : _vm._e(), _vm.request.appointmentDate ? _c('div', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.request.appointmentDate,
      "disabled": ""
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.request.appointmentTime.text,
      "disabled": ""
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_vm.$helper.permission.canMakeAppointments(_vm.user) ? _c('AppointmentDialog', {
    attrs: {
      "id": _vm.form.id,
      "request": _vm.request,
      "isNewAppointment": !_vm.request.appointmentDate,
      "offices": _vm.page.offices
    },
    on: {
      "saved": _vm.onAppointmentAdd
    }
  }) : _vm._e()], 1), _vm.request.appointmentDate && _vm.request.state !== 'CANCEL_ZAPIS' ? _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_vm.$helper.permission.canMakeAppointments(_vm.user) ? _c('div', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "red"
    },
    on: {
      "click": _vm.cancelAppointment
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-cancel")]), _vm._v(" Отменить запись ")], 1)], 1) : _vm._e(), !_vm.request.appointmentApproved ? _c('div', {
    staticClass: "mt-3"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": _vm.approveAppointment
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Подтвердить запись ")], 1)], 1) : _vm._e(), _vm.request.appointmentApproved ? _c('div', {
    staticClass: "mt-3"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": _vm.cancelApproveAppointment
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Запись подтверждена ")], 1)], 1) : _vm._e()]) : _vm._e()], 1) : _vm._e(), _c('v-row', [_vm.$helper.permission.canSeeSourceOfRequest(_vm.user) || !_vm.request ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.sources,
      "item-value": "id",
      "disabled": _vm.request !== null,
      "item-text": "name",
      "label": "Источник",
      "clearable": true
    },
    model: {
      value: _vm.form.source,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  }), _vm.form.errors.has('source') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('source')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.$helper.permission.canChangeSubdivision(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Подразделение",
      "items": _vm.subdivisions,
      "item-value": "id",
      "item-text": "name",
      "clearable": true
    },
    model: {
      value: _vm.form.subdivision,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subdivision", $$v);
      },
      expression: "form.subdivision"
    }
  }), _vm.form.errors.has('subdivision') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('subdivision')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.$helper.permission.canAssignRequest(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Оператор",
      "items": _vm.operators,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.operator,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "operator", $$v);
      },
      expression: "form.operator"
    }
  }), _vm.form.errors.has('operator') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('operator')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _vm.request !== null ? _c('v-row', [_vm.$helper.permission.canSeeCreatedAtOfRequest(_vm.user) ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt,
      "disabled": "disabled",
      "label": "Дата создания"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.assignedAt,
      "disabled": "disabled",
      "label": "Дата привязки"
    }
  })], 1), _vm.$helper.permission.canAssignCounterpart(_vm.user) ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Контрагент",
      "items": _vm.counterparts,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.counterpart,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "counterpart", $$v);
      },
      expression: "form.counterpart"
    }
  }), _vm.form.errors.has('counterpart') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('counterpart')) + " ")]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Имя"
    },
    model: {
      value: _vm.form.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  }), _vm.form.errors.has('userName') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userName')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-row', {
    staticStyle: {
      "margin-top": "-12px",
      "margin-bottom": "-12px"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-## #',
      expression: "'+7 (###) ###-##-## #'"
    }],
    attrs: {
      "label": "Телефон"
    },
    model: {
      value: _vm.form.userPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userPhone", $$v);
      },
      expression: "form.userPhone"
    }
  }), _vm.form.errors.has('userPhone') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userPhone')) + " ")]) : _vm._e()], 1), _vm.form.userPhone ? _c('v-col', {
    staticClass: "col-auto"
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "tel:".concat(_vm.form.userPhone)
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")])], 1)], 1)]) : _vm._e()], 1)], 1), _c('v-col', [_c('v-row', {
    staticStyle: {
      "margin-top": "-12px",
      "margin-bottom": "-12px"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-## #',
      expression: "'+7 (###) ###-##-## #'"
    }],
    attrs: {
      "label": "Доп. телефон"
    },
    model: {
      value: _vm.form.additionalUserPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalUserPhone", $$v);
      },
      expression: "form.additionalUserPhone"
    }
  }), _vm.form.errors.has('additionalUserPhone') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalUserPhone')) + " ")]) : _vm._e()], 1), _vm.form.additionalUserPhone ? _c('v-col', {
    staticClass: "col-auto"
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "tel:".concat(_vm.form.additionalUserPhone)
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")])], 1)], 1)]) : _vm._e()], 1)], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.form.userEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userEmail", $$v);
      },
      expression: "form.userEmail"
    }
  }), _vm.form.errors.has('userEmail') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userEmail')) + " ")]) : _vm._e()], 1), _vm.form.userIp ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Ip",
      "disabled": ""
    },
    model: {
      value: _vm.form.userIp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userIp", $$v);
      },
      expression: "form.userIp"
    }
  }), _vm.form.errors.has('userIp') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userIp')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.areas,
      "item-value": "id",
      "item-text": "name",
      "label": "Область права",
      "clearable": true
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _vm.form.errors.has('area') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('area')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.cities,
      "item-value": "id",
      "item-text": "name",
      "label": "Город",
      "clearable": true
    },
    model: {
      value: _vm.form.city,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  }), _vm.form.errors.has('city') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('city')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Проблема"
    },
    model: {
      value: _vm.form.problem,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problem", $$v);
      },
      expression: "form.problem"
    }
  }), _vm.form.errors.has('problem') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('problem')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "3"
    }
  })], 1), _vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.request ? _vm.update(false) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить ")]), _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.request ? _vm.update(true) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить и закрыть ")])], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)])], 1) : _vm._e(), _c('div', {
    staticClass: "my-10"
  }, [(_vm$request = _vm.request) !== null && _vm$request !== void 0 && _vm$request.userPhone ? _c('RequestDoubles', {
    attrs: {
      "title": "\u0417\u0430\u044F\u0432\u043A\u0438 \u043A\u043B\u0438\u0435\u043D\u0442\u0430 \u0441 \u043D\u043E\u043C\u0435\u0440\u043E\u043C: ".concat(_vm.request.userPhone),
      "filter": {
        cleanPhone: _vm.request.cleanPhone,
        isDoubles: true
      },
      "exclude": [_vm.request.id]
    }
  }) : _vm._e(), (_vm$request2 = _vm.request) !== null && _vm$request2 !== void 0 && _vm$request2.additionalUserPhone ? _c('RequestDoubles', {
    attrs: {
      "title": "\u0417\u0430\u044F\u0432\u043A\u0438 \u043A\u043B\u0438\u0435\u043D\u0442\u0430 \u0441 \u043D\u043E\u043C\u0435\u0440\u043E\u043C: ".concat(_vm.request.additionalUserPhone),
      "filter": {
        cleanPhone: _vm.request.cleanAdditionalUserPhone
      },
      "exclude": [_vm.request.id]
    }
  }) : _vm._e()], 1), _vm.request && _vm.$helper.permission.canSeeCalls(_vm.user) ? _c('CallsGrid', {
    attrs: {
      "title": "\u0412\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.userPhones),
      "filter": {
        clientPhones: [_vm.request.cleanPhone, _vm.request.cleanAdditionalUserPhone]
      }
    }
  }) : _vm._e(), _vm.request ? _c('RequestComments', {
    attrs: {
      "id": _vm.request.id
    }
  }) : _vm._e(), _vm.request && _vm.$helper.permission.canSeeRequestAnswers(_vm.user) ? _c('RequestAnswers', {
    attrs: {
      "request": _vm.request,
      "areas": _vm.areas
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }